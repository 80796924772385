<template>
    <v-container class="pa-0" fluid>
        <DeliveryComponent/>
    </v-container>
</template>

<script>
    import DeliveryComponent from "../../components/delivery/DeliveryComponent";
    import {mapState} from "vuex";
    import {ENDPOINTS} from "../../config";
    import shiftMixin from "../../mixins/pos/shiftMixin";

    export default {
        components: {
            DeliveryComponent,
        },
        mixins:[shiftMixin],
        data: () => ({
            icon: 'pe-7s-headphones icon-gradient bg-tempting-azure',
        }),
      computed:{
      },
      methods:{
      },
    }
</script>
