<template>
  <v-card tile elevation="0" :loading="loading" :outlined="false" min-height="200">
    <v-card-text class="py-0">
      <v-row no-gutters>
        <v-col v-if="this.isDateFilterEnabled" cols="12">
          <div
              class="pa-0 px-0 ma-0"
          >
            <v-btn
                class="mr-0"
                color="grey darken-2"
                outlined
                @click="setToday"
            >
              {{ $t('generic.lang_today') }}
            </v-btn>
            <v-btn
                color="grey darken-2"
                fab
                icon
                text
                @click="$refs.calendar.prev()"
            >
              <v-icon large>
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <v-btn class="elevation-0 text-black border" color="primary" fab small @click="picker=true">
              {{ new Date(requested_date).getDate() }}
            </v-btn>


            <v-btn
                color="grey darken-2"
                fab
                icon
                small
                @click="$refs.calendar.next()"
            >
              <v-icon large>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <strong v-if="$refs.calendar" class="font-weight-bolder">
              {{ $refs.calendar.title }}
            </strong>
            <v-spacer></v-spacer>
          </div>
        </v-col>
        <v-col v-if="this.isDateFilterEnabled" cols="12">
          <v-row class="fill-height pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <div style="height: 0 !important; visibility: hidden !important;">
                <v-calendar
                    ref="calendar"
                    v-model="requested_date"
                    color="primary"
                    type="day"
                ></v-calendar>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <v-btn @click="openAll"
                 color="rgb(249, 150, 0)"
                 :dark="!(loadingOpenAll||loading||unopenedOrders.length<1)"
                 :loading="loadingOpenAll"
                 :disabled="loadingOpenAll||loading||unopenedOrders.length<1">
            {{ $t('generic.lang_acceptAll') }} {{ unopenedOrders.length > 0 ? "(" + unopenedOrders.length + ")" : '' }}
          </v-btn>
          <v-btn @click="globalStatusDialog=true"
                 :disabled="loadingOpenAll||loading||selectedOrders.length<1||loadingGlobalStatus||hasExternalOrder"
                 color="purple" :dark="!(loadingOpenAll||loading||selectedOrders.length<1||loadingGlobalStatus)"
          >
            {{ $t('generic.lang_setStatus') }}
          </v-btn>
          <v-btn @click="reprintOrderBonSelected"
                 :disabled="loadingOpenAll||loading||selectedOrders.length<1||loadingGlobalStatus"
                 color="accent" :dark="!(loadingOpenAll||loading||selectedOrders.length<1||loadingGlobalStatus)"
          >
            {{ $t('generic.lang_reprint') }}
          </v-btn>
        </v-col>
        <v-col cols="6" align="right">
          <v-btn @click="selectDeselect('all')"
                 color="success"
                 dark
                 :loading="loadingOpenAll"
          >
            <font-awesome-icon class="font-weight-bold mx-2" style="font-size: 20px;"
                               :icon="['far','ballot-check']"/>
            {{ $t('generic.lang_selectAll') }}

          </v-btn>
          <v-btn @click="selectDeselect(null)"
                 color="primary"
                 dark
          >
            <font-awesome-icon class="font-weight-bold mx-2" style="font-size: 20px;"
                               :icon="['far','ballot']"/>
            {{ $t('generic.lang_unselectAll') }}

          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="orders && orders.length>0">
      <v-slide-y-transition group>
        <v-card class="mb-4 rounded-lg" v-for="(order,index) in orders" elevation="1" outlined :key="index"
                :color="selectedOrders.includes(order.uuid)?'blue lighten-5':''"
                style="border-color: #E6E9F4 !important;" @click="openOrder(order)" :ripple="!order.isOpened"
                :disabled="loadingOpenAll">

          <v-row no-gutters :class="{blink_me:!order.isOpened}">
            <v-col cols="3">
              <div class="widget-content py-1 px-2" style="color:#7E84A3;">
                <div class="widget-content-outer">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left text-center h-100">
                      <v-card elevation="0" outlined class="px-2 rounded-lg"
                              style="border-width: 2px;border-color: #E6E9F4;">
                        <p class="mb-0 font-weight-bold" style="color: #7620f0;font-size: 0.9em;">
                          {{ getTime(order.timestamp) }}
                        </p>
                        <p class="ma-0 pa-0 font-weight-bold" style="color: #9ea7d1;font-size: 0.8em;">
                          {{ getDate(order.timestamp) }}
                        </p>
                      </v-card>
                      <div class="text-center mt-1">
                        <font-awesome-icon class="mx-auto" v-if="order.isDelivery===1"
                                           style="color: #F99600;font-size: 1.4em"
                                           :icon="['fal','biking-mountain']"/>
                        <font-awesome-icon class="mx-auto" v-else style="color: #0058FF;font-size: 1.4em"
                                           :icon="['fal','burger-soda']"/>
                      </div>
                      <div class="text-center">
                        <template v-if="order.externalOrderId && order.externalProvider && $store.getters['permissions/checkModule'](81)">
                          <img width="28" :alt="order.source" :src="mergeportImage"/>
                        </template>
                        <font-awesome-icon class="mx-auto mt-1" v-else-if="order.source!=='online'" style="font-size: 1.4em"
                                           :icon="['fal','user-headset']"/>
                        <img v-else width="28" :alt="order.source" :src="localbeeIcon"/>
                      </div>
                    </div>
                    <div class="widget-content-right text-center mx-auto">
                      <p class="font-weight-medium text-wrap mx-auto my-0"
                         style="color: #7E84A3;max-width: 90%;font-size:0.9em">
                        <span class="success--text font-weight-bold">{{ order.pickUpNo }}</span><br/>
                        <span class="font-weight-bold" :class="isScheduled(order.serviceTime)?'error--text ':'success--text'">{{ order.serviceTime }}</span>
                      </p>
                      <v-btn fab depressed @click.stop="getTime(order.timestamp)" color="transparent" class="ma-0"
                             :ripple="false">
                        <font-awesome-icon class="mx-auto" style="color: #7E84A3;font-size: 2em"
                                           :icon="['fal','map-marked']"/>
                      </v-btn>

                    </div>
                  </div>
                </div>
              </div>

            </v-col>
            <v-col cols="3">
              <div class="pa-1 ma-0 font-weight-medium" style="color: #7E84A3;font-size: 0.9em;line-height: 16px"
                   v-if="order.customer">
                <p class="mb-0">
                  {{
                    (order.customer.first_name && order.customer.last_name) ? order.customer.first_name + ' ' + order.customer.last_name : (order.customer.first_name || order.customer.last_name)
                  }}<br/>
                </p>
                <p class="mb-0" v-if="order.customer.street||order.customer.street_no ">
                  {{
                    (order.customer.street && order.customer.street_no) ? order.customer.street + ' ' + order.customer.street_no : (order.customer.street || order.customer.street_no)
                  }}
                </p>
                <p class="mb-0" v-if="order.customer.city||order.customer.zip ">
                  {{
                    (order.customer.city && order.customer.zip) ? order.customer.zip + ' ' + order.customer.city : (order.customer.city || order.customer.zip)
                  }}
                </p>
                <p class="mb-0" v-if="order.customer.email">
                  {{
                    order.customer.email
                  }}
                </p>
                <p class="mb-0" v-if="order.customer.mobil_private || order.customer.tel_private">
                  {{
                    order.customer.mobil_private ? order.customer.mobil_private : order.customer.tel_private
                  }}
                </p>
                <div v-if="order.externalOrderId && order.externalProvider && $store.getters['permissions/checkModule'](81)">
                  <v-divider/>
                  <span class="font-weight-bold">{{ order.externalProvider }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <p class="pa-1 ma-0 font-weight-medium" style="color: #7E84A3;font-size: 0.9em;line-height: 16px">
                {{ order.total|currency }}<br/>
                {{ getPaymentType(order.paymentType) }}<br/>
                {{ paymentStates[order.is_payed] }}<br/>
                {{ getTotalItems(order) + ' item' + ((getTotalItems(order) > 1) ? 's' : '') }}
              </p>
            </v-col>
            <v-col cols="2" class="border-right">
              <p class="pa-1 ma-0 font-weight-medium" style="color: red;font-size: 0.9em;line-height: 16px">
                {{ order.orderFreetext }}
              </p>
            </v-col>
            <v-col cols="2" align-self="center" class="h-100">
              <div class="grid-menu grid-menu-3col">
                <div class="no-gutters row">
                  <div class="col-sm-4 col-xl-4 border-0"
                       style="border-right:1px solid lightgray !important;border-bottom:1px solid lightgray !important;">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary"
                            @click.stop="reprintOrderBon(order)">
                      <font-awesome-icon style="font-size: 1.4em" :icon="['fal','receipt']"/>
                    </button>
                  </div>
                  <div class="col-sm-4 col-xl-4 border-0"
                       style="border-right:1px solid lightgray !important;border-bottom:1px solid lightgray !important;">
                    <button @click.stop="printInterimBill(order)"
                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary">
                      <font-awesome-icon style="font-size: 1.4em" :icon="['fal','print']"/>
                    </button>
                  </div>
                  <div class="col-sm-4 col-md-4 col-xl-4 border-0"
                       style="border-bottom:1px solid lightgray !important;">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-success"
                            @click.stop="editOrder(order)">
                      <font-awesome-icon style="font-size: 1.4em" :icon="['fal','edit']"/>
                    </button>
                  </div>
                  <div class="col-sm-4 col-md-4 col-xl-4 border-0" style="border-right:1px solid lightgray !important;">
                    <b-dropdown class="w-100" toggle-class="pa-0 mr-0" menu-class="dropdown-menu-xl" variant="link"
                                no-caret right>
                      <button slot="button-content" @click="getMergeportOrder(order)"
                              class="btn-icon-vertical btn-square btn-transition btn btn-outline-warning">
                        <font-awesome-icon style="font-size: 1.4em" :icon="['fal','comment-alt-dots']"/>
                      </button>
                      <template slot="default" slot-scope="slotProps">
                        <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-plum-plate">
                          <div class="menu-header-image dd-header-bg-1"></div>
                          <div class="menu-header-content text-white">
                            <h5 class="menu-header-title">{{$t('delivery.lang_orderStatus')}}</h5>
                            <h6 class="menu-header-subtitle">{{$t('delivery.lang_setOrderStatus')}}</h6>
                          </div>
                        </div>
                      </div>
                      <div class="grid-menu grid-menu-xl grid-menu-3col">
                        <v-progress-linear color="purple" indeterminate v-if="loadingMergeportOrder"/>
                        <div class="no-gutters row" v-if="!loadingMergeportOrder">
                          <template v-if="mergeportOrder && order.externalOrderId && order.externalProvider && $store.getters['permissions/checkModule'](81)">
                            <template v-if="mergeportOrder.possibleStateChanges && mergeportOrder.possibleStateChanges.length > 0">
                              <div class="col-sm-6 col-xl-4" v-for="(status,index) in mergeportOrder.possibleStateChanges" :key="index">
                                <button class="btn-icon-vertical btn-square btn-transition btn"
                                :style="status.state===order.orderStatus?'color: #fff !important;background-color: lightblue;':''"
                                style="display: grid;" @click.stop="setMergeOrderStatus(order,status.state);slotProps.hide()">
                                <font-awesome-icon class="mx-auto my-1" :style="status.state===order.orderStatus?'color: #fff !important;':''" :icon="['fal',mergeportPossibleActions(status.state).icon]" size="2x"/>
                                {{ mergeportPossibleActions(status.state).name }}
                                </button>
                              </div>
                            </template>
                          <template v-else>
                            <div class="col-12">
                              <h6 class="text-center my-1">{{ $t('generic.lang_noPossibleStatusChange') }}</h6>
                            </div>
                          </template>
                          </template>
                          <template v-else>
                            <div class="col-sm-6 col-xl-4" v-for="(status,index) in orderStatuses" :key="index">
                              <button class="btn-icon-vertical btn-square btn-transition btn"
                                      :style="status.id===order.orderStatus?'color: #fff !important;background-color: '+status.color+';':''"
                                      style="display: grid;" @click.stop="setOrderStatus(order,status)">
                                <font-awesome-icon class="mx-auto my-1"
                                                   :icon="['fal',status.icon?status.icon:'person-carry']"
                                                   :style="{color:status.id===order.orderStatus?'white':status.color,fontSize:'30px'}"
                                                   align="center"/>
                                {{ status.name }}
                              </button>
                            </div>
                          </template>
                        </div>
                      </div>
                      </template>
                      
                    </b-dropdown>
                  </div>
                  <div class="col-sm-4 col-xl-4 border-0" style="border-right:1px solid lightgray !important;">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-info"
                            @click.stop="sendOrder(order)">
                      <font-awesome-icon style="font-size: 1.4em" :icon="['fal','rocket-launch']"/>
                    </button>
                  </div>
                  <div class="col-sm-4 col-xl-4 border-0">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger"
                            @click.stop="deleteOrder(order.uuid)">
                      <font-awesome-icon style="font-size: 1.4em" :icon="['fal','times-circle']"/>
                    </button>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-slide-y-transition>
    </v-card-text>
    <v-card-text v-else class="text-center">
      <h3>{{ $t('cashier.lang_noDeliveryOrders') }}</h3>
    </v-card-text>
    <DeliveryDriverSelectDialog :show-dialog="showDeliverBoysDialog" @close="showDeliverBoysDialog=false"
                                @setDriver="payOrder"/>
    <PrintOrderBonComponent ref="orderbonPrint"/>
    <!-- GLOBAL ORDER STATUS DIALOG -->
    <v-dialog v-model="globalStatusDialog" max-width="600" persistent>
      <v-card max-width="600" elevation="0" outlined>
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-plum-plate">
            <div class="menu-header-image dd-header-bg-1 text-right">
              <v-btn icon fab small dark class="ma-0 mt-2" @click="!loadingGlobalStatus?globalStatusDialog=false:null">
                <v-icon size="20" color="white">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
            <div class="menu-header-content text-white mt-5">
              <h5 class="menu-header-title">{{ $t('delivery.lang_orderStatus') }}</h5>
              <h6 class="menu-header-subtitle">{{ $t('delivery.lang_setOrdersStatus') }}</h6>
            </div>
          </div>
        </div>
        <div class="grid-menu grid-menu-xl grid-menu-3col">
          <v-progress-linear color="purple" indeterminate v-if="loadingGlobalStatus"/>
          <div class="no-gutters row">

            <div class="col-sm-6 col-xl-4" v-for="(status,index) in orderStatuses" :key="index">
              <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link"
                      style="display: grid;" @click="setOrdersStatuses(status)">
                <font-awesome-icon class="mx-auto my-1"
                                   :icon="['fal',status.icon?status.icon:'person-carry']"
                                   :style="{color:status.color,fontSize:'30px'}" align="center"/>
                {{ status.name }}
              </button>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- SALE LOADING DIALOG -->
    <v-dialog v-model="loadingSale" persistent width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    width="3"
                    size="50"
                />
                <p style="margin-top: 15px;" class="text-center">{{ $t('accounting.lang_sellingInProgress') }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" persistent width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <p style="font-size: 17px;">{{ errorDialogMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" v-if="errorDialogShowCancelButton" :disabled="errorDialogLoading" text
                 @click="cancelErrorDialog">{{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="errorDialogLoading" :loading="errorDialogLoading" @click="closeErrorDialog">
            {{ errorDialogConfirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- date picker dialog -->
    <v-dialog v-if="this.isDateFilterEnabled" v-model="picker" max-width="400px" scrollable>
      <v-card align="center" class="pa-0 ma-0">
        <v-card-title class="pa-0 px-0 ma-0">
          <v-spacer/>
          <v-btn icon @click="picker=false">
            <v-icon color="error">
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 ma-0">
          <v-date-picker
              v-model="requested_date"
              event-color="green lighten-1"
              width="100%"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-text>
      <FinishedOrdersComponent/>
    </v-card-text>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from 'vuex'
import mixin from "../../mixins/KeyboardMixIns"
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faBallot, faBallotCheck,} from '@fortawesome/pro-regular-svg-icons';
import {
  faBiking,
  faBikingMountain,
  faBinoculars,
  faBurgerSoda,
  faCheck,
  faCommentAltDots,
  faEdit,
  faFlagCheckered,
  faHatChef,
  faKnifeKitchen,
  faMapMarked,
  faMapMarkerAlt,
  faPersonCarry,
  faPizza,
  faPrint,
  faProjector,
  faReceipt,
  faRocketLaunch,
  faSalad,
  faStopwatch,
  faTimesCircle,
  faUserHeadset,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons';
import {createVoucherPrintingData} from "@/plugins/printing/printVoucher";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import cashoutMixin from "@/mixins/pos/cashout/cashoutMixin";
import printInterimBill from "@/mixins/pos/buttons/printInterimBill";
import DeliveryDriverSelectDialog from "@/components/delivery/DeliveryDriverSelectDialog";
import PrintOrderBonComponent from "./PrintOrderBonComponent";
import FinishedOrdersComponent from "./FinishedOrdersComponent";
import {printDataFromPrinter} from "../../plugins/printing/printerController";
import {geol} from "@/main";
import {roundNumber} from "../../plugins/round";

library.add(
    faUserHeadset,
    faMapMarked,
    faBikingMountain,
    faBurgerSoda,
    faPrint,
    faReceipt,
    faCommentAltDots,
    faEdit,
    faTimesCircle,
    faRocketLaunch,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faSalad,
    faBallot,
    faBallotCheck,
);

export default {
  name: "PendingDeliveriesComponent",
  components: {
    FinishedOrdersComponent,
    PrintOrderBonComponent,
    DeliveryDriverSelectDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },
  mixins: [mixin, cashoutMixin/*, cashoutTSEMixin*/, printInterimBill],
  data() {
    return {
      picker: false,
      requested_date: "",
      loading: false,
      loadingMergeportOrder: false,
      mergeportOrder: null,
      loadingOpenAll: false,
      showDeliverBoysDialog: false,
      paymentStates: ['not paid', 'paid'],
      localbeeIcon: require('@/assets/images/delivery/Local Bee orange.svg'),
      mergeportImage: require('@/assets/images/mergeport.png'),
      orders: [],
      orderStatuses: [],
      deliverArea: null,
      selectedOrder: null,
      selectedOrders: [],
      invoiceXMLPrintData: [],
      vouchersToPrint: [],
      globalStatusDialog: false,
      loadingGlobalStatus: false,
      loadingPrinting: false,
      loadingSale: false,
      invoiceResultData: {},
      splitPayments: {},
      selectedPaymentType: 0,
      deliveryInvoiceItems: [],
      currentCustomer: null,
      availablePayments: [
        {
          id: 1,
          payment: this.$t('generic.lang_cash'),
        },
        {
          id: 3,
          payment: this.$t('generic.lang_ec'),
        },
        {
          id: 9,
          payment: this.$t('generic.lang_creditCard'),
        },
        {
          id: 11,
          payment: 'Paypal',
        },
        {
          id: 25,
          payment: 'Stripe',
        },
      ],
      // -- ERROR DIALOG --
      errorDialog: false,
      errorDialogShowCancelButton: false,
      errorDialogMessage: "",
      errorDialogCloseAction: "",
      errorDialogLoading: false,
      errorDialogConfirmButtonText: this.$t('generic.lang_next'),
      // ----
    }
  },
  computed: {
    ...mapState([
      'pos',
      'api',
      'tse',
      'cashierIDs',
      'settings',
      'posLayoutTemplates',
      'touchkeyboard',
      'user',
      'deposit'
    ]),
    mergeportPossibleActions(){
      return (action)=>{
        const actions = {
          "ready":{
            name:this.$t('generic.lang_ready'),
            icon: 'person-carry'
          },
          "pickedUp":{
            name:this.$t('generic.lang_pickedUp'),
            icon: 'person-carry'
          },
          "inDelivery":{
            name:this.$t('generic.lang_inDelivery'),
            icon: 'person-carry'
          },
          "delivered":{
            name:this.$t('generic.lang_delivered'),
            icon: 'person-carry'
          },
          "rejectedByPOS":{
            name:this.$t('generic.lang_rejectedByPOS'),
            icon: 'person-carry'
          },
          "fetchedByPOS":{
            name:this.$t('generic.lang_fetchedByPOS'),
            icon: 'person-carry'
          },
          "acceptedByPOS":{
            name:this.$t('generic.lang_acceptedByPOS'),
            icon: 'person-carry'
          },
          "preparing":{
            name:this.$t('generic.lang_preparing'),
            icon: 'person-carry'
          }
        }

        return actions[action];
      }
    },
    isScheduled(){
      return serviceTime => RegExp(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/).test(serviceTime);
    },
    isDateFilterEnabled() {
      return this.settings.settings.hasOwnProperty("lb_show_orders_date_filter") && this.$store.getters['settings/getSettingValue']("lb_show_orders_date_filter") === "1"
    },
    offSiteTax() {
      if (this.settings.settings.hasOwnProperty("offSiteTax")) {
          return Number(this.settings.settings.offSiteTax);
      }

      return 0;
    },
    /**
     * @description this function allow us to convert string date like 2022-05-09 to a moment js
     * object because when use moment(strDate) sometimes returns passed date minus one day for example if we pass 2022-05-09 its will return 2022-05-08
     * we're using it as computed not a method to keep real time rendering updates instead of re-render the component manually
     * @returns {String | null | Moment}
     */
    getValidMomentDateFromString() {
      /**
       * @param {String} strDate
       * @param {String} format
       */
      return (strDate, format = null) => {
        if (strDate && this.$moment(strDate).isValid()) {
          const m = this.$moment(new Date(strDate).getTime());

          if (format) return m.format(format);

          return m;
        }

        return null;
      }
    },
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    unopenedOrders() {
      if (!this.orders || (this.orders && this.orders.length < 1))
        return [];

      return this.orders.filter(order => !order.isOpened)
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          let template = this.posLayoutTemplates.templates.some(template => {
            if (templateID === template.id) {
              return (template.multiUser === 1 || template.multiUser === true)
            }
          });
          return template;
        }
      }
      return false;
    },
    activeUserID() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return parseInt(this.activeUser.userID);

      return this.user.userID;
    },
    realUser() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return this.activeUser;

      return this.user;
    },
    vouchersValue() {
      let total = 0;

      if (!this.selectedOrder)
        return total;

      this.selectedOrder.saleObj.forEach((item) => {
        if ((item.ean.includes("V$") || item.ean.includes("GF$") || item.ean.includes("ZMYLE$")) && !item.isVoid) {
          total += item.sellPrice;
        }
      });

      return total * -1;
    },
    disableGlobalCashDrawer() {
      //search for the current cashier
      let current = this.cashierIDs.cashiers.find((cashier) => cashier.id === this.api.auth.cashierID);
      if (current)
        return current.disableCashDrawer === 1;
      else return false
    },
    orderItemsToPrint(){
      if(!this.selectedOrder)
        return [];

      let items = this._.cloneDeep(this.selectedOrder.saleObj);
      if(this.selectedOrder.isDelivery === 1)
        return items;

      items.forEach((invoiceItem) => {
        if (invoiceItem.takeAwayTaxing > 0 && invoiceItem.isMenuItem < 1) {
            invoiceItem.taxValue = this.offSiteTax;
        }
      })
      return items;
    },
    hasExternalOrder(){
      const externalOrder = this.orders.find(order => this.selectedOrders.includes(order.uuid) && order.externalOrderId && order.externalProvider && this.$store.getters['permissions/checkModule'](81))
      return !!externalOrder;
    }
  },
  watch: {
    requested_date: {
      handler() {
        this.getOrders();
      },
      immediate: false,
    }
  },
  sockets: {
    "delivery.refreshLockedOrders": function (payload) {
      //console.log('delivery.refreshLockedOrders', payload)
    },
    "delivery.checkOrderLock": function (payload) {
    //  console.log('delivery.checkOrderLock', payload)
    },
    "delivery.orderCreated": function (payload) {
      this.getOrders();
    },
    "delivery.refreshOrders": function (payload) {
      this.getOrders();
    },

  },
  methods: {
    setToday() {
      let dt = new Date();
      this.requested_date = this.$moment(dt.getTime()).format("YYYY-MM-DD")
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    async printInterimBill(order) {
      let data = {
        saleID: 0,
        ts: order.timestamp,
        moneyGiven: 0,
        paymentType: order.paymentType,
        isDelivery: order.isDelivery === 1,
        serviceTime: order.serviceTime,
        pickUpNo: order.pickUpNo,
        freeText: order.orderFreetext,
        isAsap: order.isAsap,
      }

      this.setCustomer(order.customerID).then(() => {
        data.customer = this.currentCustomer;
        this.startPrintInterimBill(order, 'delivery', data)
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err
        });
      })
    },

    setMergeOrderStatus(order, status){
      this.axios.post('3rdPartyIntegrations/mergeport/patch/state/', {
          id: order.externalOrderId,
          state: status,
      }).then(res => {
        if (res.data.success) {
          order.orderStatus = status;
          this.$root.$emit('updateOrders');
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_statusUpdated'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
      })
    },

    setOrderStatus(order, status) {
      this.axios.post(ENDPOINTS.DELIVERY.ORDER.STATUS.BULKUPDATE, {
      orderUUIDS: [order.uuid],
      statusID: status.id,
    }).then(res => {
      if (res.data.success) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_statusUpdated'),
          color: "success"
        });
        order.orderStatus = status.id;
      } else {
        Events.$emit("showSnackbar", {
          message: res.data.msg || this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }
    }).catch(err => {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
        color: "error"
      });
    }).finally(() => {
    })
    },

    setOrdersStatuses(status) {
      this.loadingGlobalStatus = true;
      this.axios.post(ENDPOINTS.DELIVERY.ORDER.STATUS.BULKUPDATE, {
        orderUUIDS: this.selectedOrders,
        statusID: status.id,
      }).then(res => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_statusUpdated'),
            color: "success"
          });
          this.$emit('updateOrders')
          this.selectedOrders = [];
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.globalStatusDialog = false;
        this.loadingGlobalStatus = false;
      })
    },
    selectDeselect(targetOrder) {
      if (targetOrder === null) {
        this.selectedOrders = [];
        return;
      } else if (targetOrder === 'all') {
        this.selectedOrders = this.orders.map(order => order.uuid);
        return;
      } 

      let index = this.selectedOrders.findIndex(uuid => targetOrder.uuid === uuid)

      if (index >= 0) {
        this.selectedOrders.splice(index, 1);
      } else {
        this.selectedOrders.push(targetOrder.uuid)
      }
    },
    reprintOrderBonSelected() {
      let orders = this.orders.filter(order => this.selectedOrders.includes(order.uuid))
      if (orders && orders.length < 1)
        return;

      this.$refs.orderbonPrint.printOrderBons(orders).then(() => {
        this.selectedOrders = [];
      })
    },
    reprintOrderBon(order) {
      this.$refs.orderbonPrint.printOrderBons([order])
    },
    openAll() {
      if (this.unopenedOrders.length < 1) {
        return;
      }
      this.axios.post(ENDPOINTS.DELIVERY.ORDER.OPEN.UPDATEALL).then(res => {
        if (res.data.success) {
          this.$root.$emit('updateOrders')
          this.$refs.orderbonPrint.printOrderBons(this.unopenedOrders).finally(() => {
            this.unopenedOrders.forEach(order => {
              order.isOpened = true;
            })
          })
          this.$socket.emit('delivery.refreshOrders');
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingOpenAll = false;
      })
    },
    async openOrder(order) {
      if (order.isOpened) {
        this.selectDeselect(order)
        return;
      }

      this.axios.post(ENDPOINTS.DELIVERY.ORDER.OPEN.UPDATE, {
        orderUUID: order.uuid
      }).then(res => {
        if (res.data.success) {
          order.isOpened = true;
          this.$root.$emit('updateOrders')
          this.$refs.orderbonPrint.printOrderBons([order])
          this.$socket.emit('delivery.refreshOrders')
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    getTime(timestamp) {
      return moment.unix(timestamp).format('HH:mm')
    },
    getDate(timestamp) {
      return moment.unix(timestamp).format('DD.MM.YY')
    },
    getPaymentType(id) {
      let name = this.availablePayments.find(payment => payment.id === Number(id))
      return (name) ? name.payment : this.$t('generic.lang_paymentNotFound');
    },

    getOrders() {
      this.loading = true;
      this.axios.post(ENDPOINTS.DELIVERY.ORDERS.GET, {
        date: this.getValidMomentDateFromString(this.requested_date, "DD.MM.YYYY")
      }).then(res => {
        if (res.data.success) {
          this.orders = res.data.orders;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },

    getMergeportOrder(order) {
      if(!(order.externalOrderId && order.externalProvider) || !this.$store.getters['permissions/checkModule'](81))
        return;

      this.loadingMergeportOrder = true;
      this.mergeportOrder = null;
      this.axios.post('/3rdPartyIntegrations/mergeport/get/order/',{
        id:order.externalOrderId
      }).then(res => {
        if (res.data.status) {
          this.mergeportOrder = res.data.order;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingMergeportOrder = false;
      })
    },

    getOrderStatuses() {
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYSTATUS.GETACTIVE, {}).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.orderStatuses = res.data.data;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    editOrder(order) {
      let query = {useDeliveryService: order.uuid}

      this.$store.commit("pos/gastro/changeParty", {
        posType: 'gastro',
        party: {
          name: order.partyName
        }
      });

      this.$store.dispatch("pos/gastro/setTable", {
        table: {
          name: order.orderName,
          parties: [{name: this.pos.gastro.party.name}],
        }
      })

      this.$store.dispatch("pos/gastro/setDeliveryService", true)
          .then(() => {
            // TELL WEBSOCKET THAT WE LOCK THE ORDER
            this.$socket.emit("delivery.lockOrder", order.uuid);

            this.$router.push({name: 'pos.gastro', query: {useDeliveryService: order.uuid}})
          })
    },
    deleteOrder(uuid) {
      this.$swal({
        title: this.$t('delivery.lang_deleteDeliveryOrderTitle'),
        text: this.$t('delivery.lang_deleteDeliveryOrderText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.DELIVERY.ORDER.DELETE, {
            orderUUIDs: [uuid],
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_deletedSuccessfully'),
                color: "success"
              });
              this.getOrders();
              this.$emit('updateOrders');
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    sendOrder(order) {
      if (order.isDelivery === 0) {
        if (order.is_payed) {
          this.selectedOrder = order;
          this.payOrder();
        } else {
          this.$store.commit("pos/gastro/changeParty", {
            posType: 'gastro',
            party: {
              name: order.partyName
            }
          });
          this.$store.dispatch("pos/gastro/setTable", {
            table: {
              name: order.orderName,
              parties: [{name: this.pos.gastro.party.name}],
            }
          })
          this.$store.dispatch("pos/gastro/setDeliveryService", true);
          this.$store.dispatch("pos/gastro/setTakeAway", 1);
          this.$router.push({name: 'pos.gastro', query: {useDeliveryService: order.uuid, checkout: ''}})
        }

        /* *

        * */
      } else {
        this.showDeliverBoysDialog = true;
        this.selectedOrder = order;
      }
    },
    async getPossibleDeliveryAreas(term) {
      this.deliverArea = null;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.SEARCH, {
        zip: term
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          this.deliverArea = res.data;
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {

      })

    },
    getTotalItems(order) {
      let total = 0;
      if (!order.saleObj) {
        return 0;
      }
      order.saleObj.forEach(item => {
        if (item?.amount.toString().includes('.')) {
          total += 1;
        } else {
          total += Number(item?.amount)
        }
      })
      return total
    },
    payOrder(id = null) {
      this.loadingSale = true;
      this.selectedPaymentType = this.selectedOrder.paymentType;
      this.deliveryInvoiceItems = this.selectedOrder.saleObj;
      let postData = {
        saleAction: "CASHOUT",
        posVersion: "DELIVERY",
        paymentType: this.selectedOrder.paymentType,
        globalFreeText: this.selectedOrder.orderFreetext,
        totalPrice: Number(this.selectedOrder.total),
        goodsValue: roundNumber(this.vouchersValue + Number(this.selectedOrder.total),2),
        progressToken: this.pos.progressToken,
        OrderArray: this.selectedOrder.saleObj,
        newOrderArray: [],
        moneyGiven: 0,
        takeAway: 1,
        customerID: this.selectedOrder.customerID,
        tableName: this.selectedOrder.orderName,
        partyName: this.selectedOrder.partyName
      };

      if (id !== null) {
        postData.driverID = id;
      }

      this.axios.post(ENDPOINTS.POS.SALE.CREATE, postData).then((res) => {
        if (res.data.status === "PROGRESS_TOKEN") {
          // UPDATE PROGRESS TOKEN
          this.$store.commit("pos/setProgressToken", res.data.apiProgressToken);

          this.payOrder(id);
        } else if (res.data.status === "SUCCESS") {

          // UPDATE PROGRESS TOKEN
          this.$store.commit("pos/setProgressToken", res.data.apiProgressToken);

          // SET CUSTOMER

          this.setCustomer(this.selectedOrder.customerID).then(() => {
            this.invoiceResultData = res.data;
            this.saleCreated();
            this.$emit('updateOrders')
          }).catch(err => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err
            });
          })


        } else {

          this.loadingSale = false;
        }

      }).catch(err => {

      }).finally(() => {
        this.loadingSale = false;
        this.getOrders();
      })
    },
    saleCreated() {
      //CHECK IF TSE DEVICE IS AVAILABLE
      if (this.fiscalClient !== null) {
        if (this.fiscalClient.device.type === null) {
          this.fiscalClient.device.type = "epsonTSE";
        }
        if (this.fiscalClient.device.type === "fiskaly") {
          this.printWithoutTSE();
        } else {
          this.checkTSEPrint();
        }
      } else {
        this.printWithoutTSE();
      }
    },
    async setCustomer(id) {

      // GET CUSTOMER
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: id,
      }).then(async (res) => {
        if (res.data.success) {
          this.currentCustomer = res.data.customer;
        } else {
          await this.$store.dispatch("pos/setCustomer", {
            posType: 'gastro',
            customer: null,
            setCustomerOnly:true,
          })
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
      });
    },
    printWithoutTSE(withTSEerror = false) {
      //CHECK IF INVOICE PRINTER EXISTS
      if (!this.invoicePrinter) {
        this.checkVoucher();
        return;
      }

      //RESET INVOICE ERROR MESSAGE
      this.errorInvoicePrintDialogMessage = "";

      this.loadingPrinting = true;

      //GENERATE INVOICE DATA

      let data = {
        saleID: this.invoiceResultData.invoiceID,
        cashierID: this.invoiceResultData.cashierID,
        userID: this.invoiceResultData.userID,
        ts: this.invoiceResultData.ts,
        moneyGiven: Number(this.selectedOrder.total), //this.invoiceResultData.moneyGiven,
        paymentType: this.invoiceResultData.paymentType,
        splitPayments: [],
        isDelivery: this.selectedOrder.isDelivery === 1,
        serviceTime: this.selectedOrder.serviceTime,
        pickUpNo: this.selectedOrder.pickUpNo,
        customer: this.currentCustomer,
        isAsap: this.selectedOrder.isAsap,
        freeText: this.selectedOrder.orderFreetext,
        offSite: 1,
      };

      data["tableNo"] = this.selectedOrder.localBeeId;


      //TRY TO CREATE PRINT DATA
      let printData;
      try {
        printData = createInvoicePrintingData(this.orderItemsToPrint, data, this.invoicePrinter.options, this.realUser, false, false, Number(this.selectedOrder.total), this.vouchersValue);

        // CHECK IF CASH DRAWER SHOULD NOT BE OPENED WHEN PAYING WITH EC
        if (this.disableGlobalCashDrawer || (this.selectedOrder.paymentType === 3 || this.selectedOrder.paymentType === 9) && parseInt(this.$store.getters['settings/getSettingValue']('ecPaymentOpenCashDrawer')) === 0) {
          // THEN GET INDEX OF OPENCASHDRAWER ELEMENT AND REMOVE IT
          let cashDrawerElementIndex = printData.findIndex((el) => {
            return el.hasOwnProperty("cashDrawer");
          })

          // REMOVE OPEN CASH DRAWER ELEMENT
          this.$delete(printData, cashDrawerElementIndex);
        }

        if (withTSEerror === true) {
          printData.push({
            text: "\n" + geol.t('accounting.lang_tseIsUnreachable') + "\n"
          });
        } else {

          let fiskalQrCode = this.invoiceResultData.fiskalQRCode;
          let fiskalData = this.invoiceResultData.fiskalData;

          if (fiskalData !== null) {
            printData.push({
              fontSize: 1
            });

            printData.push({
              font: "B"
            });
            printData.push({
              text: geol.t('accounting.lang_eazSerNr') + ": " + fiskalData.client_id
            });
            printData.push({
              text: geol.t('accounting.lang_tseSerNr') + ": " + fiskalData.tss_serial_number
            });
            printData.push({
              text: geol.t('accounting.lang_tseStart') + ": " + fiskalData.time_start
            });
            printData.push({
              text: geol.t('accounting.lang_tseStop') + ":  " + fiskalData.time_end
            });
            printData.push({
              text: geol.t('accounting.lang_tseTrxNr') + ": " + fiskalData.number
            });
            printData.push({
              text: geol.t('accounting.lang_tseSigCount') + ":" + fiskalData.signature.counter
            });
            printData.push({
              text: geol.t('accounting.lang_signatur') + ":  " + fiskalData.signature.value
            });

            printData.push({
              align: "center"
            });

            //ADD QR CODE
            printData.push({
              qrCode: fiskalQrCode
            });
          }
        }

        // CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[(printData.length - 1)];
        if (!lastElememt.hasOwnProperty("cut"))
          printData.push({cut: true});

        // SAVE PRINT DATA
        this.invoiceXMLPrintData = printData;
      } catch (err) {

        this.loadingPrinting = false;
        this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "check_vouchers");
        return;
      }

      // EPSON EPOS PRINT
      let printerPromises = [printDataFromPrinter(this.invoicePrinter,printData)];
      if (data.isDelivery && Number(this.settings.settings.lb_enable_printing_twice) === 1)
        printerPromises.push(printDataFromPrinter(this.invoicePrinter,printData))

      Promise.all(printerPromises).then(() => {
        this.checkVoucher();
      }).catch((err) => {
        this.showErrorDialog(this.$t('generic.lang_printReceiptError') + '  (' + err + ')', false, "check_vouchers");
      }).finally(()=>{
        this.loadingPrinting = false;
      })


    },
    checkVoucher() {
      //CHECK IF VOUCHERS WERE CREATED
      if (this.invoiceResultData.vouchers.length > 0) {
        //CHECK IF INVOICE PRINTER IS SETUP
        if (!this.invoicePrinter) {
          this.showErrorDialog(this.$t('generic.lang_vouchersWereSoldButNoCashRegisterPrinterWasSetUp'))
          return;
        }

        this.vouchersToPrint = this.invoiceResultData.vouchers;

        //PRINT ALL VOUCHERS
        this.invoiceResultData.vouchers.forEach((voucher) => {
          //GET VOUCHER PRINTING DATA
          let printVouchersData = createVoucherPrintingData(voucher.voucherUUID, voucher.voucherValue, voucher.freeText);

          //SEND DATA TO PRINTER
          // EPSON EPOS PRINT
          printDataFromPrinter(this.invoicePrinter,printVouchersData).then(()=>{

          }).catch((err)=>{
            this.showErrorDialog(this.$t('erp.lang_voucher') + ' ' + voucher.voucherUUID + ' ' + this.$t('generic.lang_couldNotBePrinted'), true, "reprint_vouchers", "Retry");
          }).finally(()=>{

          })

        });
      } else {

      }
    },
    reprintVouchers() {
      this.errorDialogLoading = true;

      //PRINT ALL VOUCHERS
      this.vouchersToPrint.forEach((voucher) => {
        //GET VOUCHER PRINTING DATA
        let printVouchersData = createVoucherPrintingData(voucher.voucherUUID, voucher.voucherValue, voucher.freeText);

        //SEND DATA TO PRINTER
        // EPSON EPOS PRINT
        printDataFromPrinter(this.invoicePrinter,printVouchersData).then(()=>{

        }).catch((err)=>{
          this.showErrorDialog(this.$t('generic.lang_vouchersCouldNotBePrinted'), true, "reprint_vouchers", "Retry");
        }).finally(()=>{

        })

      });
    },
    showErrorDialog(message, showCancelButton = false, closeAction = null, confirmButtonText = null) {
      this.errorDialogLoading = false;
      this.errorDialogShowCancelButton = false;

      //CHECK IF ARE ON MOBILE POS AND SHOULD SHOW THE BOOTSTRAP TOAST
      if (closeAction === "close_only") {
        this.$bvToast.toast(message, {
          title: 'error',
          autoHideDelay: 4000,
          variant: 'danger'
        });

        return;
      }

      this.errorDialogMessage = message;
      this.errorDialogShowCancelButton = showCancelButton;

      if (closeAction !== null)
        this.errorDialogCloseAction = closeAction;
      else
        this.errorDialogCloseAction = "";

      if (confirmButtonText !== null)
        this.errorDialogConfirmButtonText = confirmButtonText;
      else
        this.errorDialogConfirmButtonText = this.$t('generic.lang_next');

      //OPEN ERROR DIALOG
      this.errorDialog = true;
    },

    closeErrorDialog() {
      this.errorDialog = false;

      if (this.errorDialogCloseAction === "close_only")
        return;

      if (this.errorDialogCloseAction === "check_vouchers") {
        this.checkVoucher();
        return;
      }

      if (this.errorDialogCloseAction === "reprint_vouchers") {
        this.reprintVouchers();
        return;
      }

      if (this.errorDialogCloseAction === "print_without_tse") {
        this.printWithoutTSE(true);
        return;
      }

      if (this.errorDialogCloseAction === "show_after_sale_dialog") {
        return;
      }

    },
  },
  mounted() {
    this.setToday();

    //this.getOrders();
    this.getOrderStatuses();
    this.$root.$on('updateOrders', () => {
      this.getOrders();
    })

    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },
  beforeDestroy() {
    this.$root.$off('updateOrders');
  },

}
</script>

<style scoped>
.v-card--link:before {
  background: none;
}

.blink_me {
  animation: blinker 1.2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .2;
  }
}
</style>
