<template>
  <v-dialog v-model="dialog" max-width="60%" no-click-animation @click:outside="dialog=false">
    <v-card :loading="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_selectDeliveryBoy') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="dialog=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="overflow-auto px-1" style="height: 350px;">
        <v-list>
          <v-list-item-group mandatory color="indigo">
          <template v-for="(driver,i)  in drivers">
            <v-list-item  :key="i"  @click="selected=driver.id" selectable ripple>
              <v-list-item-avatar>
                <img :src="require('@/assets/images/no_avatar.png')"/>
              </v-list-item-avatar>
              <v-list-item-content>
                {{driver.firstName+' '+driver.lastName}}
              </v-list-item-content>
            </v-list-item>
            <v-divider class="ma-0 " :key="'d-'+i"/>
          </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider class="ma-0"/>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="success" :disabled="selected===null" @click="setDriver()">
          {{ $t('generic.lang_select') }}
        </v-btn>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";

export default {
  name: "DeliveryDriverSelectDialog",
  props:{
    showDialog:Boolean,
  },
  data(){
    return{
      dialog:false,
      loading:false,
      selected:null,
      drivers:[],
    }
  },
  watch:{
    showDialog(val){
      this.dialog=val;
      if(val){
        this.getDrivers()
      }
    },
    dialog(val){
      if(!val){
        this.$emit('close')
      }
    }
  },
  methods:{
    getDrivers(){
      this.loading = true;
      this.drivers=[];
      this.axios.post(ENDPOINTS.SETTINGS.USER.GETDRIVERS,{}).then((res) => {
        if(res.data.status==='SUCCESS'){
          this.drivers.push({id:0,firstName:'Assignable',lastName:''})
          this.drivers.push(...res.data.data);
        }else{

        }
      }).catch((err) => {
        console.log(err)
      }).finally(()=>{
        this.loading = false;
      })
    },
    setDriver(){
      this.$emit('setDriver',this.selected);
      this.$emit('close');
    }
  },
}
</script>

<style scoped>

</style>
