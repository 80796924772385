<template>
  <div>
    <v-card class="pa-2 my-2" elevation="0" tile :style="this.$vuetify.theme.dark? '' : 'background-color: #e1e4e6 !important;'">
      <v-row>
        <v-col cols="6" class="border-right">
          <v-card-text :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="pa-0 rounded-lg h-100" :style="this.$vuetify.theme.dark? '' : 'background-color: white !important;'">
            <v-row no-gutters justify="start">
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="border-bottom pa-1">
                <div class="widget-content pa-0 px-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <font-awesome-icon style="color: #21D59B;font-size: 1.5em" :icon="['fal','shopping-basket']"/>
                        </div>
                      </div>
                      <div class="widget-content-left">
                        <div style="font-size: 0.9em"
                             class="widget-heading font-weight-bold pb-1">
                          {{ $t('delivery.lang_newOrders') }}
                        </div>
                        <div class="widget-numbers font-weight-bold py-1" style="color: #21D59B;font-size: 1.2em">
                          <count-to :startVal='0' :endVal='newOrders' :duration='1500'></count-to>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="border-bottom pa-1">
                <div class="widget-content pa-0 px-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <font-awesome-icon style="color: #FFC700; font-size: 1.5em" size="2x" :icon="['fal','knife-kitchen']"/>
                        </div>
                      </div>
                      <div class="widget-content-left">
                        <div style="font-size: 0.9em"
                             class="widget-heading font-weight-bold pb-1">{{ $t("delivery.lang_inProcessing") }}
                        </div>
                        <div class="widget-numbers font-weight-bold py-1" style="color: #FFC700; font-size: 1.2em">
                          <count-to :startVal='0' :endVal='inProcessing' :duration='1500'></count-to>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="border-bottom pa-1">
                <div class="widget-content pa-0 px-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <font-awesome-icon style="color: #57B8FF; font-size: 1.5em" :icon="['fal','rocket-launch']"/>
                        </div>
                      </div>
                      <div class="widget-content-left">
                        <div style="font-size: 0.9em"
                             class="widget-heading font-weight-bold pb-1 text-wrap">
                          {{ $t('delivery.lang_onRoad') }}
                        </div>
                        <div class="widget-numbers font-weight-bold py-1" style="color: #57B8FF; font-size: 1.2em">
                          <count-to :startVal='0' :endVal='onRoad' :duration='1500'></count-to>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="pa-1"
                     :class="{'border-bottom':$vuetify.breakpoint.mdAndDown}">
                <div class="widget-content pa-0 px-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <font-awesome-icon size="2x" style="color: #F99600; font-size: 1.5em" :icon="['fal','biking-mountain']"/>
                        </div>
                      </div>
                      <div class="widget-content-left">
                        <div style="font-size: 0.9em"
                             class="widget-heading font-weight-bold pb-1">
                          {{ $t('delivery.lang_delivered') }}
                        </div>
                        <div class="widget-numbers font-weight-bold py-1" style="color: #F99600; font-size: 1.2em">
                          <count-to :startVal='0' :endVal='delivered' :duration='1500'></count-to>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="pa-1">
                <div class="widget-content pa-0 pl-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <font-awesome-icon size="2x" style="color: #0058FF; font-size: 1.5em" :icon="['fal','burger-soda']"/>
                        </div>
                      </div>
                      <div class="widget-content-left">
                        <div style="font-size: 0.9em"
                             class="widget-heading font-weight-bold pb-1">{{ $t('delivery.lang_pickedUp') }}
                        </div>
                        <div class="widget-numbers font-weight-bold py-1" style="color: #0058FF; font-size: 1.2em">
                          <count-to :startVal='0' :endVal='takeAway' :duration='1500'></count-to>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="pa-1" align="center">

                <!--start: delivery statuses -->
                <div class="widget-content pa-0 px-2">
                  <div class="widget-content-outer">
                    <div class="widget-content-wrapper" style="color: #F99600">
                      <div class="widget-content-left">
                        <div class="mr-2 ">
                          <v-btn @click="showQuickSettings=true" :ripple="false" fab depressed color="transparent">
                            <font-awesome-icon class="text-warning darken-4" style="font-size: 2.5em"
                                               :icon="['fal','exclamation-triangle']"/>
                          </v-btn>
                        </div>
                      </div>
                      <div class="widget-content-left text-left">
                        <div v-if="settings.isClosed" style="font-size: 0.9em"
                             class="widget-heading warning--text font-weight-bold pb-1">
                          {{ $t('generic.lang_closed') }}
                        </div>

                        <div v-if="settings.offersPickUp" style="font-size: 0.9em"
                             class="widget-heading warning--text font-weight-bold pb-1">
                          {{ $t('delivery.lang_pickup') }}
                        </div>

                        <div v-if="settings.offersDelivery" style="font-size: 0.9em"
                             class="widget-heading warning--text font-weight-bold pb-1">
                          {{ $t('delivery.lang_delivery') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <v-dialog v-model="showQuickSettings" max-width="500">
                  <v-card min-width="500">
                    <v-card-title>
                      {{ $t('generic.lang_quickSettings') }}
                    </v-card-title>
                    <v-divider class="ma-0"/>
                    <v-card-text class="text-center">
                      <v-switch class="mx-1" v-model="settings.isClosed" @change="updateQuickSettings" dense
                                hide-details :label="$t('generic.lang_closed')" color="#7620f0"
                                :loading="loadingSettings">

                      </v-switch>

                      <v-switch class="mx-1" v-model="settings.offersPickUp" @change="updateQuickSettings" dense
                                hide-details :label="$t('delivery.lang_pickup')" color="#7620f0"
                                :loading="loadingSettings">

                      </v-switch>

                      <v-switch class="mx-1" v-model="settings.offersDelivery" @change="updateQuickSettings" dense
                                hide-details :label="$t('delivery.lang_delivery')" color="#7620f0"
                                :loading="loadingSettings">

                      </v-switch>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                      <v-btn @click="showQuickSettings=false" color="primary lighten-1" class="rounded-md">
                        {{ $t('generic.lang_ok') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!--end: delivery statuses -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="6" align-self="center">
          <v-row>
            <v-col cols="12">
              <v-card elevation="0" outlined class="pa-0 px-2 rounded-lg"
                      style="border-width: 2px;border-color: #E6E9F4;">
                <v-row no-gutters justify="space-between">
                  <v-col cols="6" align="center" align-self="center" style="color: red;">
                    <font-awesome-icon class="fsize-1" :icon="['fal','stopwatch']"/>
                    <span class="font-weight-bold" style="font-size: 1.1em !important;">
                                {{ '01:50:53' }}
                          </span>
                  </v-col>
                  <v-col cols="6" align="center" align-self="center" class="text--secondary">
                    <font-awesome-icon class="fsize-1" :icon="['fal','clock']"/>
                    <span class="font-weight-bold" style="font-size: 1.1em !important;">
                                {{ currentTime }}
                        </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" align="center" class="border-bottom pa-1">
              <v-btn color="#327CA4" dark
                     large class="ma-1 px-1" @click="$router.push({name:'pos.gastro.tables'})">
                <font-awesome-icon class="pa-2 pl-0" size="3x" :icon="['fal','couch']"/>
                <span class="font-weight-bold" style="letter-spacing: 4px">
                  {{ $t('generic.lang_table') }}
                </span>
              </v-btn>
              <v-btn color="primary lighten-1" dark
                     large class="ma-1 px-1" @click="$router.push({name:'pos.gastro.tresenverkauf'})">
                <font-awesome-icon class="pa-2 pl-0" size="3x" :icon="['fal','cash-register']"/>
                <span class="font-weight-bold" style="letter-spacing: 4px">
                  {{ $t('generic.lang_cashier_checkout') }}
                </span>
              </v-btn>
              <v-btn color="warning lighten-1" dark
                     large class="ma-1 px-1" @click="directOrder">
                <span class="font-weight-bold" style="letter-spacing: 4px">
                  {{ $t('delivery.lang_pickup') }}
                </span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined :background-color="this.$vuetify.theme.dark? '' : 'white'" :label="$t('generic.lang_searchInDeliveryArea')"
                            color="purple"
                            :error="false" :hide-details="true" dense
                            v-model="search" placeholder="Search..." :loading="loadingCustomers"
              >
                <template v-slot:append>
                  <v-icon v-if="!search">
                    mdi-magnify
                  </v-icon>
                  <v-btn v-else fab color="primary" x-small class="mt-n1" @click="showCreateCustomerDialog=true">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="customers&&customers.length>0&&search" class="overflow-auto" outlined elevation="2">

      <b-table ref="cashOverviewTable" striped hover responsive
               :items="customers" style="max-height: 400px;"
               :fields="fields" v-on:row-clicked="selectCustomer" :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
        <template v-slot:cell(street)="row">
          {{ (row.item.street) ? row.item.street + ' ' : ' ' }}
          {{ (row.item.streetNumber) ? row.item.streetNumber + ' \n' : ' \n' }}
          {{ (row.item.zip) ? row.item.zip + ' ' : ' ' }}
          {{ (row.item.city) ? row.item.city + ' ' : ' ' }}
        </template>
      </b-table>

    </v-card>
    <v-divider v-if="customers&&customers.length>0&&search"/>
    <v-dialog v-model="showCustomerDetailsDialog" @click:outside="showCustomerDetailsDialog=false" width="80%"
              no-click-animation scrollable :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card v-if="selectedCustomer">

        <v-card-title class="pa-0 ma-0 position-relative">
          <MapLocationPicker v-model="selectedCustomer.geoLoc" readonly :height="250"></MapLocationPicker>
          <v-btn style="position: absolute;top:0;right:0;z-index: 1000;"
                 @click="showCustomerDetailsDialog=false" elevation="2" x-small
                 fab color="error"
          >
            <v-icon size="20" color="white">
              mdi-close
            </v-icon>
          </v-btn>
          <v-avatar tile
                    style="z-index: 10000 !important;border-color:darkgrey !important;border-width: 4px !important;position: absolute;bottom:-35px;right:10px;"
                    color="grey  lighten-4" class="border rounded-lg" size="70">
            <img :src="selectedCustomer.image" alt="Avatar 4">
          </v-avatar>
        </v-card-title>
        <v-card-text class="pa-0" style="max-height: 600px;">
          <v-container fluid class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" align="center" class="border-bottom">
                <v-btn class="rounded-pill" color="#21d59b" dark @click="goToOrder()" :loading="loadingInfo">{{$t('delivery.lang_goToOrder')}}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="4" class="border-right border-bottom">
                <v-row no-gutters>
                  <v-col cols="6" sm="4" md="12" lg="12" xl="12" class="border-right border-bottom h-100">
                    <div class="widget-chart">
                      <div class="widget-heading font-weight-bold fsize-1 mb-1">{{ $t('generic.lang_dateOfLastSale') }}</div>
                      <div class="widget-subheading font-weight-bold fsize-1">
                        {{ getDate(selectedCustomer.lastSaleTimestamp) || $t('generic.lang_unknown') }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" sm="4" md="12" lg="12" xl="12" class="border-right border-bottom h-100">
                    <div class="widget-chart">
                      <div class="widget-heading font-weight-bold fsize-1 mb-1">{{ $t('generic.lang_totalOfLastSale') }}</div>
                      <div class="widget-subheading font-weight-bold fsize-1">
                        {{ (selectedCustomer.lastSaleTotal || 0)|currency }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="12" lg="12" xl="12" class="border-right h-100">
                    <div class="widget-chart">
                      <div class="widget-heading font-weight-bold fsize-1 mb-1">{{ $t('generic.lang_salesLastMonthTotal') }}</div>
                      <div class="widget-subheading font-weight-bold fsize-1">
                        {{ (selectedCustomer.lastMonthSales || 0)|currency }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="12" md="8" class="elevation-1">
                <v-row no-gutters class="px-2 py-2" justify="end">
                  <v-col sm="12" md="6">
                    <h5 v-if="selectedCustomer.company" class="mb-0">
                      {{ (selectedCustomer.company) ? selectedCustomer.company + ' ' : ' ' }}</h5>

                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="150" height="20"/>
                    </p>
                    <h5 v-if="selectedCustomer.preName || selectedCustomer.lastName " class="mb-0">

                      {{
                        (selectedCustomer.preName && selectedCustomer.lastName) ? selectedCustomer.preName + ' ' + selectedCustomer.lastName : selectedCustomer.preName || selectedCustomer.lastName
                      }}

                    </h5>
                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="200" height="20"/>
                    </p>
                    <h5 v-if="selectedCustomer.phone" class="mb-0">
                      {{ (selectedCustomer.phone) ? selectedCustomer.phone + ' ' : ' ' }}</h5>

                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="190" height="20"/>
                    </p>
                    <h5 v-if="selectedCustomer.email" class="mb-0">
                      {{ (selectedCustomer.email) ? selectedCustomer.email + ' ' : ' ' }}</h5>

                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="170" height="20"/>
                    </p>
                  </v-col>
                  <v-col sm="12" md="6" :align="($vuetify.breakpoint.smAndDown)?'':'end'">

                    <h5 v-if="selectedCustomer.street||selectedCustomer.streetNo ">
                      <span>
                        {{
                          (selectedCustomer.street && selectedCustomer.streetNo) ? selectedCustomer.street + ' ' + selectedCustomer.streetNo : (selectedCustomer.street || selectedCustomer.streetNo)
                        }}
                      </span>
                    </h5>

                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="250" height="20"/>
                    </p>
                    <h5 v-if="selectedCustomer.city||selectedCustomer.zip ">
                      <span>
                      {{
                          (selectedCustomer.city && selectedCustomer.zip) ? selectedCustomer.city + ' ' + selectedCustomer.zip : (selectedCustomer.city || selectedCustomer.zip)
                        }}
                      </span>
                    </h5>

                    <p class="mb-0" v-if="loadingInfo">
                      <v-skeleton-loader type="text" max-width="160" height="20"/>
                    </p>

                  </v-col>
                  <v-col cols="12" sm="10" md="8" lg="6">
                    <v-textarea rows="4" :label="$t('generic.lang_internalNotes')+':'" v-model="selectedCustomer.internalNote"
                                class="rounded-lg " style="border-color: transparent !important;" outlined no-resize
                                filled dense readonly disabled>

                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <CustomerCreateDialog :show-dialog="showCreateCustomerDialog" :search="search"
                          @close="showCreateCustomerDialog=false" @createCustomer="newCustomerOrder($event)"/>
  </div>
</template>

<script>

import CustomerCreateDialog from "@/components/delivery/CreateCustomerDialog";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import randomString from "randomstring";
import MapLocationPicker from "@/components/common/MapLocationPicker";
import countTo from 'vue-count-to'
import {Events} from "@/plugins/events";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faBikingMountain,
  faBurgerSoda,
  faCashRegister,
  faCouch,
  faExclamationTriangle,
  faKnifeKitchen,
  faReply,
  faRocketLaunch,
  faShoppingBasket
} from '@fortawesome/pro-light-svg-icons';
import {ENDPOINTS} from "../../config";
import moment from 'moment'
import {mapState} from "vuex";

library.add(
    faKnifeKitchen,
    faRocketLaunch,
    faShoppingBasket,
    faBurgerSoda,
    faBikingMountain,
    faCashRegister,
    faCouch,
    faReply,
    faExclamationTriangle
)

export default {
  name: "DeliveryOverview",
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    countTo, MapLocationPicker,
    CustomerCreateDialog,
  },
  computed: {
    ...mapState([
      'pos',
    ]),
    disableZipCodeCheck() {
      return Number(this.$store.getters['settings/getSettingValue']('lb_disableZipCodeCheck')) === 1
    }
  },
  sockets: {
    "delivery.orderCreated": function (payload) {
      this.getStats();
    },
  },
  data() {
    return {
      placeTelImg: require('@/assets/images/delivery/placetel-logo.png'),
      newOrders: 0,
      inProcessing: 0,
      onRoad: 0,
      delivered: 0,
      takeAway: 0,
      customerAvatar: require("@/assets/images/no_avatar.png"),
      currentTime: 0,
      loadingSettings: false,
      showQuickSettings: false,
      timer: 0,
      settings: {
        isClosed: 0,
        offersDelivery: 0,
        offersPickUp: 0
      },
      search: null,
      awaitingSearch: null,
      customers: [],
      position: [],
      loadingCustomers: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'company',
          label: this.$t('generic.lang_company'),
          sortable: false,
        },
        {
          key: 'full_name',
          label: this.$t('customers.lang_custname'),
          sortable: false,
        },
        {
          key: 'street',
          label: this.$t('generic.lang_address'),
          sortable: false,
        },
        {
          key: 'zip',
          label: this.$t('customers.lang_cust_zip'),
          sortable: false,
        },
      ],
      showCustomerDetailsDialog: false,
      selectedCustomer: null,
      showCreateCustomerDialog: false,
      loadingInfo: false,
      deliverArea: null,
    }
  },
  watch: {
    search: function (val) {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (val) {
          this.loadCustomers()
        }
      }, 500);
    },
  },
  methods: {
    getStats() {
      this.axios.post(ENDPOINTS.DELIVERY.ORDERS.STATS, {
        startDate: moment().startOf('day').unix(),
        endDate: moment().endOf('day').unix(),
      }).then(res => {
        if (res.data.success) {
          this.newOrders = res.data.statistics.unopenedOrders;
          this.inProcessing = res.data.statistics.openedOrders;
          this.onRoad = res.data.statistics.unopenedOrders;
          this.delivered = res.data.statistics.deliveredOrders;
          this.takeAway = res.data.statistics.pickedUpOrders;
        }
      })
    },
    updateQuickSettings() {
      this.loadingSettings = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.QUICK.UPDATE, {
        offersPickUp: (this.settings.offersPickUp) ? 1 : 0,
        offersDelivery: (this.settings.offersDelivery) ? 1 : 0,
        isClosed: (this.settings.isClosed) ? 1 : 0
      }).then(res => {
        if (res.data.success) {
          this.setting = res.data.settings
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSettings = false;
      })
    },

    selectCustomer(customer) {
      this.selectedCustomer = {};
      this.showCustomerDetailsDialog = true
      this.getCustomerInfo(customer.id);

    },
    async setCustomer(id = null) {

      this.loadingCustomers = true;
      // GET CUSTOMER
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: id ? id : this.selectedCustomer.id,
      }).then(async (res) => {
        if (res.data.success) {
          let customer = res.data.customer;
          // GET CUSTOMER ALLERGENS
          await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GETWITHNAMES, {
            customerId: id ? id : this.selectedCustomer.id,
          }).then(async (res) => {
            if (res.data.success) {
              customer.allergens = res.data.allergens;
              //ADD CUSTOMER TO INVOICE
              await this.$store.dispatch("pos/setCustomer", {
                posType: 'gastro',
                customer: customer,
                setCustomerOnly:true,
              })
            }
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingCustomers = false;
      });
    },
    directOrder() {
      let query = {useDeliveryService: ''}
      this.$store.commit("pos/gastro/changeParty", {
        posType: 'gastro',
        party: {
          name: "Partei 1"
        }
      });
      this.$store.dispatch("pos/gastro/setTable", {
        table: {
          name: "delivery_" + randomString.generate(15),
          parties: [{name: 'Partei 1'}],
        }
      })

      this.$store.commit('pos/gastro/setDeliveryInfo', null);
      this.$store.dispatch("pos/gastro/setDelivery", false);
      this.$store.dispatch("pos/gastro/setDeliveryService", true)
      this.$store.dispatch("pos/setCustomer", {
        posType: 'gastro',
        customer: null,
        setCustomerOnly:true,
      })
      this.$router.push({name: 'pos.gastro', query: query})
    },
    goToOrder(id = null) {
      let query = {useDeliveryService: ''}

      this.setCustomer(id).then(res => {
        this.$store.commit("pos/gastro/changeParty", {
          posType: 'gastro',
          party: {
            name: "Partei 1"
          }
        });

        this.$store.commit("pos/gastro/setTable", {
          table: {
            name: "delivery_" + randomString.generate(15),
            parties: [{name: 'Partei 1'}],
          }
        })

        if (this.deliverArea && this.deliverArea.length === 1) {

          this.$store.commit('pos/gastro/setDeliveryInfo', this.deliverArea[0])
          this.$store.commit("pos/gastro/setDelivery", true)


        } else if (this.deliverArea && this.deliverArea.length > 1 || this.disableZipCodeCheck) {

          this.$store.commit('pos/gastro/setDeliveryInfo', null)
          this.$store.commit("pos/gastro/setDelivery", false);
          
          query.hasMultipleArea = this.selectedCustomer.zip && !this.disableZipCodeCheck ? this.selectedCustomer.zip:'';

        } else {
          this.$store.commit('pos/gastro/setDeliveryInfo', null);
          this.$store.commit("pos/gastro/setDelivery", false);

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_weCantDeliverToTheSelectedCustomersAddress'),
            color: "warning"
          });
        }

        this.$store.dispatch("pos/gastro/setDeliveryService", true)
        this.$router.push({name: 'pos.gastro', query: query})
      }).catch(err=>{
        console.log(err);
      })
    },
    async getCustomerInfo(id) {
      this.loadingInfo = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETINFO, {
        customerID: id
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.selectedCustomer = res.data.data
          this.selectedCustomer.id = id;
          if (!this.selectedCustomer.image) {
            this.selectedCustomer.image = this.customerAvatar
          }
          if(!this.disableZipCodeCheck){
            this.getPossibleDeliveryAreas(this.selectedCustomer.zip)
          }
        }
      }).catch(err => {
        console.log(err.message)

      }).finally(() => {
        this.loadingInfo = false;
      })
    },
    loadCustomers() {
      this.loadingCustomers = true;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search: this.search,
        }
      }).then((res) => {
        this.loadingCustomers = false;
        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.customers = res.data.results;
          } else {
            this.customers = [];
          }
        }
      })
    },
    async getPossibleDeliveryAreas(term) {
      this.deliverArea = null;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.SEARCH, {
        zip: term
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          this.deliverArea = res.data;
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {

      })
    },
    getQuickSettings() {
      this.loadingSettings = true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.QUICK.GET)
          .then(res => {
            if (res.data.success) {
              this.settings.offersPickUp = (res.data.settings.offersPickUp === 1)
              this.settings.offersDelivery = (res.data.settings.offersDelivery === 1)
              this.settings.isClosed = (res.data.settings.isClosed === 1)
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSettings = false;
      })
    },
    syncTime() {
      let self = this;
      let flag = false;
      this.timer = setInterval(() => {
        flag = !flag;
        if (flag) {
          self.currentTime = moment.unix(moment.now() / 1000).format('HH:mm');
        } else {
          self.currentTime = moment.unix(moment.now() / 1000).format('HH mm');
        }
      }, 1000);
    },
    getDate(timestamp) {
      return moment.unix(timestamp).calendar()
    },
    newCustomerOrder(id = null) {
      this.getCustomerInfo(id).then(() => {
        this.goToOrder(id)
      })
    }
  },
  mounted() {
    this.getStats()
    this.syncTime();
    this.getQuickSettings();
    this.$root.$on('updateOrders', () => {
      this.getStats();
    })
  },
  beforeDestroy() {
    this.$root.$off('updateOrders')
    clearInterval(this.timer);
  }

}
</script>

<style scoped>

</style>
