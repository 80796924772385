<template>
  <v-dialog v-model="showDialog" max-width="800px" scrollable @click:outside="closeDialog" @keydown="($event.keyCode===27)?closeDialog():''" fullscreen transition="scroll-y-transition">
    <v-card width="100%">
      <v-toolbar class="text-black heading pt-1 pl-4 pr-1 pb-1" elevation="0" dense max-height="50px" height="40px">
        <v-toolbar-title>
          {{ $t('customers.lang_cust_create') }}
        </v-toolbar-title>
        <v-spacer/>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-toolbar>
      <v-divider class="ma-0"/>

      <v-card-text class="pa-0">
        <v-form ref="createCustomerForm" v-model="valid">
          <v-tabs v-model="tab"
              color="deep-purple accent-4"
              center-active
          >
            <v-tab>{{$t('generic.lang_info')}}</v-tab>
          </v-tabs>
          <v-divider class="ma-0"/>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row >
                  <v-col cols="12" sm="12" md="7">
                    <v-row>
                      <!-- Vorname -->
                      <v-col cols="12" >
                        <v-text-field v-model="firstName"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('customers.lang_cust_prename')"
                                      :loading="loading"
                                      :rules="firstNameRules"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>

                      <!-- Nachname -->
                      <v-col cols="12" sm="12" md="6" >
                        <v-text-field v-model="lastName"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('customers.lang_cust_name')"
                                      :loading="loading"
                                      :rules="lastNameRules"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="number"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('customers.lang_number')"
                                      :loading="loading"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <!-- E-Mail -->
                      <v-col cols="12" >
                        <v-text-field v-model="email"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_emailAdresse')"
                                      :loading="loading"
                                      dense

                                      required
                                      type="email"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="street"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_street')"
                                      :loading="loading"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="streetNumber"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('customers.lang_cust_streetNo')"
                                      :loading="loading"
                                      dense :rules:="[rules.required,]"
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="7">
                        <v-text-field v-model="city"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('settings.lang_city')"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="5">
                        <v-text-field v-model="zip"
                                      :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                                      :disabled="loading"
                                      :label="$t('customers.lang_cust_zip')"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="5">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="building"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_building')"
                                      :loading="loading"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="staircase"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_Staircase')"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="keypad1"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_KeyPad')+' 1'"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="keypad2"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_KeyPad')+' 2'"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="interphone"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('erp.lang_interphone')"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="floorNo"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_floorNo')"
                                      :loading="loading"
                                      dense

                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="door"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :disabled="loading"
                                      :label="$t('generic.lang_door')"
                                      :loading="loading"
                                      dense
                                      required
                                      type="text"
                                      @focus="showTouchKeyboard"
                                      outlined
                                      hide-details
                        />
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-select v-model="customerGroupID"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :disabled="loading"
                                  :label="$t('customers.lang_custGroup')"
                                  item-text="name"
                                  item-value="id"
                                  :loading="loading" :items="customerGroups"
                                  dense hide-details
                                  outlined
                                  required
                                  @focus="showTouchKeyboard"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field dense
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :label="$t('customers.lang_CustCardNumber')"
                                      @focus="showTouchKeyboard"
                                      autocomplete="off"
                                      prefix="CC$"
                                      outlined hide-details
                                      :disabled="loading"
                                      required
                                      v-model="customerCardID"
                        >
                          <template v-slot:append >
                            <v-btn fav icon color="primary" x-small class="ma-0 mr-n1" >
                              <v-icon @click="generateToken()">mdi-qrcode</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>

              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-divider class="pa-0 ma-0"/>
      <v-card-actions >
        <v-btn @click="closeDialog" color="error" text class="">{{$t('generic.lang_cancel')}}</v-btn>

        <v-btn @click="createNewCustomer()" :disabled="!valid||loading"
               color="primary" text v-if="this.$store.getters['permissions/checkPermission']('create_cust')"
               class=" text-right">{{$t('customers.lang_create_NewCust')}}
        </v-btn>
      </v-card-actions>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard"
                            :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import KeyboardMixIns from "../../mixins/KeyboardMixIns";

export default {
  name: "CreateCustomerDialog",
  mixins: [KeyboardMixIns],
  props:{
    showDialog:Boolean,
    search:String,
  },
  data(){
    return{
      loading: false,
      valid: false,
      tab:0,
      firstName: "",
      firstNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      lastName: "",
      lastNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      rules: {
        required:v => !!v || this.$t('generic.lang_requiredField'),
      },
      email: "",
      number:'',
      street: "",
      streetNumber: "",
      zip: "",
      city: "",
      customerGroups: [],
      building:null,
      staircase:null,
      keypad1:null,
      keypad2:null,
      interphone:null,
      floorNo:null,
      door:null,
      customerGroupID:null,
      customerCardID:null,
    }
  },
  watch:{
    showDialog(val){
      if(val){
        if(isNaN(Number(this.search))){
          this.firstName=this.search;
        }else{
          this.number=this.search;
        }
      }else{
        this.$refs.createCustomerForm.reset();
      }
    },
  },

  methods:{
    closeDialog(){
      this.$emit('close');
    },
    getCustomerGroups(){
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.GETALL,{

      }).then(res=>{
        if(res.data.success){
          this.customerGroups=res.data.data
        }
      }).catch(err=>{

      }).finally(()=>{

      })
    },
    generateToken(){
      this.loading=true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GENERATETOKEN,{

      }).then(res=>{
        if(res.data.STATUS==="SUCCESS"){
          this.customerCardID=res.data.UUID
        }
      }).catch(err=>{

      }).finally(()=>{
        this.loading=false;
      })
    },
    createNewCustomer(){
      if (!this.$refs.createCustomerForm.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        street: this.street,
        street_no: this.streetNumber,
        mobil_private: this.number,
        zip: this.zip,
        city: this.city,
        building:this.building,
        keyPad1:this.keypad1,
        keyPad2:this.keypad2,
        interphone:this.interphone,
        staircase:this.staircase,
        floor:this.floorNo,
        door:this.door,
        customerCardID:this.customerCardID,
        customerGroup:this.customerGroupID,
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          this.$emit("createCustomer", res.data.customerID);
          this.$emit('close');
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
  },

  mounted() {
    this.getCustomerGroups()
  },
}
</script>

<style scoped>

</style>