<template>
  <v-card>
    <Datatable
        :api-endpoint="ENDPOINTS.DATATABLES.DELIVERY.INVOICES"
        :datatable-headers="datatableHeaders" no-excel-export
        show-display-buttons
        @displayEntry="displayEntry"
    >
      <template v-slot:item.type="{item}">
        {{item.type?$t('delivery.lang_delivery'):$t('delivery.lang_pickup')}}
      </template>
      <template v-slot:item.date="{item}">
        {{getDate(item.date)}}
      </template>
      <template v-slot:item.total="{item}">
        {{item.total | currency}}
      </template>
    </Datatable>
    <v-dialog v-model="dialog" persistent max-width="60%" no-click-animation>
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('accounting.lang_bill') }}</v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0"/>
        <v-card-text class="overflow-auto pa-0 py-1" style="height: 350px;">
          <v-container fluid v-if="!loading" class="pa-1">
            <div class="invoice-box">
              <table cellpadding="0" cellspacing="0">
                <tr class="information">
                  <td colspan="4">
                    <table>
                      <tr>
                        <td>
                          <!-- CUSTOMER DATA -->
                          <div v-if="invoiceCustomerData.id > 0">
                            {{ $t('accounting.lang_InvoiceRecipient') }}:<br>
                            <span v-if="invoiceCustomerData.mobilPrivate !== null">{{
                                invoiceCustomerData.mobilPrivate
                              }}<br></span>
                            <span v-if="invoiceCustomerData.company !== null">{{
                                invoiceCustomerData.company
                              }}<br></span>
                            {{ invoiceCustomerData.first_name }}
                            {{ invoiceCustomerData.last_name }}<br>{{
                              invoiceCustomerData.street_no
                            }}<br>{{ invoiceCustomerData.zip }}
                            {{ invoiceCustomerData.city }}
                          </div>
                        </td>
                        <td>
                          {{ $t('accounting.lang_invoiceID') }}: {{ invoiceNo }}<br>
                          {{ $t('accounting.lang_invoiceOrderNo') }}: {{ orderNo }}<br>
                          {{ $t('accounting.lang_dateOfInvoice') }}: {{ saleTimeStamp }}<br>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

              </table>

              <b-table :fields="fields" :items="computedInvoicePositions"
                       hover
                       striped>
              </b-table>
              <br>
              <span style="float:right;"><h4>{{ $t('generic.lang_subtotal') }}: {{ invoiceTotalNet | currency }}</h4></span><br
                style="clear:both;">
              <span style="float:right;"><h4>{{ $t('erp.lang_mwst') }}: {{ invoiceTotalTax | currency }}</h4></span><br
                style="clear:both;">
              <span style="float:right;"><h4
                  style="font-weight:bold;">{{ $t('generic.lang_total') }}: {{ invoiceTotal | currency }}</h4></span>

              <h4>{{ $t('accounting.lang_InvoiceNotes') }}</h4>

              <br style="clear:both;">

            </div>
          </v-container>
          <v-progress-circular v-else indeterminate color="orange" class="mx-auto d-flex" size="60">

          </v-progress-circular>
        </v-card-text>
        <v-divider class="ma-0"/>
        <v-card-actions class="text-right">
          <v-btn color="error" dark @click="dialog=false">
            {{$t("generic.lang_close")}}
          </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from "../../config";
import moment from "moment"
import {Events} from "../../plugins/events";

export default {
  name: "FinishedOrdersComponent",
  components: {Datatable},
  data(){
    return{
      ENDPOINTS,
      dialog:false,
      loading:false,
      invoicePositions: [],
      invoiceCustomerData: [],
      items: [],
      invoiceNo: null,
      orderNo: null,
      saleTimeStamp: null,
      invoiceTotal: 0.00,
      invoiceTotalNet: 0.00,
      invoiceTotalTax: 0.00,
      datatableHeaders:[
        {
          text:'uuid',
          value:'uuid',
          hide: true,
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {
          text:this.$t('generic.lang_date'),
          value:'date',
        },
        {
          text:this.$t('generic.lang_customer'),
          value:'customer',
        },
        {
          text:this.$t('generic.lang_total'),
          value:'total',
        },
        {
          text:this.$t('generic.lang_receipttype'),
          value:'type',
        },
      ]
    }
  },
  computed:{
    fields() {
      return [
        {
          key: "pos",
          sortable: false,
          label: 'Pos.'
        },
        {
          key: "descr",
          sortable: false,
          label: this.$t('generic.lang_item')
        },
        {
          key: "qty",
          sortable: false,
          label: this.$t('erp.lang_QTY')
        },
        {
          key: "unitPrice",
          sortable: false,
          label: this.$t('erp.lang_ware_UnitPrice'),
          headerTitle: this.$t('generic.lang_ware_UnitPrice')
        },
        {
          key: "total",
          sortable: false,
          label: this.$t('generic.lang_total')
        }
      ]
    },
    computedInvoicePositions() {
      let items = this._.cloneDeep(this.items);

      return items.map((item) => {
        item.total = this.$options.filters.currency(item.total);
        item.unitPrice = this.$options.filters.currency(item.unitPrice);
        return item;
      });
    },
  },
  methods:{
    getDate(timestamp){
      return moment.unix(timestamp).format("HH:mm DD.MM.YY")
    },
    displayEntry(entry){
      this.dialog = true;
      this.loading = true;

        this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.GET, {
          invoiceID: entry.uuid,
        }).then(res => {
          if (res.data.success) {
            this.orderNo = res.data.data.invoiceData.invoiceSaleID;
            this.invoiceNo = "" + res.data.data.invoiceData.invoiceSaleRealm + res.data.data.invoiceData.invoiceSaleID + res.data.data.invoiceData.invoiceRandomizerID;
            this.saleTimeStamp = res.data.data.invoiceData.saleTimeStamp;
            this.invoiceTotalTax = res.data.data.invoiceData.totalTax;
            this.invoiceTotal = res.data.data.invoiceData.invoiceTotal;
            this.invoiceTotalNet = res.data.data.invoiceData.invoiceTotalNet;
            this.items = res.data.data.invoicePositionData;
            this.invoiceCustomerData = res.data.data.invoiceData.customer_data;
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch(err => {
          console.log(err)
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped>

.invoice-box {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>