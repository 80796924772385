<template>
  <v-card elevation="0" width="100%" class="mt-4" tile :outlined="false">
     <v-row class="">
      <v-col cols="12" class="py-0">
        <DeliveryOverview @searchCustomer="customers"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PendingDeliveriesComponent/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DeliveryOverview from "@/components/delivery/DeliveryOverview";
import PendingDeliveriesComponent from "@/components/delivery/PendingDeliveriesComponent";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {mapState} from 'vuex'
import {
  faStopwatch,
  faClock,
} from '@fortawesome/pro-light-svg-icons';
import PlaceTelComponent from "./PlaceTelComponent";

library.add(
    faClock,
    faStopwatch,
)

export default {
  name: "DeliveryComponent",
  components: {
    PlaceTelComponent,
    PendingDeliveriesComponent,
    DeliveryOverview,
    'font-awesome-icon':FontAwesomeIcon,
  },
  data(){
    return{
      customerAvatar:require("@/assets/images/no_avatar.png"),
      currentTime:0,
      loadingSettings:false,
      timer:0,
      settings:{
        isClosed:0,
        offersDelivery: 0,
        offersPickUp: 0
      },
      search:null,
      awaitingSearch:null,
      customers:[],
      position:[],
      loadingCustomers:false,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'company',
          label: this.$t('generic.lang_company'),
          sortable: false,
        },
        {
          key: 'full_name',
          label: this.$t('customers.lang_custname'),
          sortable: false,
        },
        {
          key: 'street',
          label: this.$t('generic.lang_address'),
          sortable: false,
        },
        {
          key: 'zip',
          label: this.$t('customers.lang_cust_zip'),
          sortable: false,
        },
      ],
      showCustomerDetailsDialog:false,
      selectedCustomer:null,
      showCreateCustomerDialog:false,
      loadingInfo:false,
      deliverArea:null,
    }
  },
  watch:{

  },
  computed:{
    ...mapState([
      'pos',
    ]),
  },
  methods:{
  },
  mounted() {
    this.$store.dispatch("pos/gastro/setDeliveryService", false)
  }
}
</script>

<style scoped>
.v-label{
  font-size: 14px !important;
}
</style>
